import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params, ResolveEnd, Router } from '@angular/router';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { cloneDeep, difference, isUndefined } from 'lodash';

import { ColDef } from 'ag-grid';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarIcon,
  GridToolbarSwitch,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  ComponentNames,
  GridColDefs,
  GridFilters,
} from './constants/phasing-grid.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import {
  GridConfig,
  GridConfigFeatures,
} from 'src/app/shared/components/base/grid/entities/grid-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { GridObservables } from 'src/app/shared/components/base/grid/entities/grid-observables';
import { OpportunityResponseItem } from 'src/app/shared/services/entities/grids/opportunities-response';
import {
  MetricsOptions,
  OpportunityTypes,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { GridCellIcon } from 'src/app/shared/components/base/grid/entities/grid-cell-icon';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { ActiveDates } from 'src/app/shared/constants/filters.constants';
import { OpportunityPhasingModalConfig } from 'src/app/shared/components/opportunity-phasing-modal/entities/opportunity-phasing-modal-config';
import { PhasingResponseItem } from 'src/app/shared/services/entities/grids/phasing-response';
import { PhasingGridUtils } from './utils/phasing-grid.utils';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';
import { GridValidationsUtils } from 'src/app/shared/utils/grid-validations.utils';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { StorageType } from 'src/app/core/constants/storage.constants';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { TabControlNames } from 'src/app/shared/components/board-modal/components/board-phasing-tab/constants/phasing-tab.constants';
import { BoardTabControl } from 'src/app/shared/services/entities/board-response';

import { PhasingGridService } from 'src/app/shared/services/grids/phasing-grid.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { AppToastsService } from 'src/app/shared/services/app-toasts.service';
import { SecurityService } from 'src/app/shared/services/security.service';
import { OpportunityPhasingModalService } from 'src/app/shared/services/modals/opportunity-phasing-modal.service';
import { BoardService } from 'src/app/shared/services/board.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faExpand,
  faCompress,
  faPencilAlt,
  faEye,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-phasing-grid',
  templateUrl: './phasing-grid.component.html',
  styleUrls: ['./phasing-grid.component.scss'],
})
export class PhasingGridComponent implements OnInit, OnDestroy {
  @Output() expandChangedEvent = new EventEmitter<boolean>();
  @Output() metricChangedEvent = new EventEmitter<TextValuePair>();

  subscription = new Subscription();

  loadChangedEvent = new Subject<boolean>();
  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);
  filtersChangedEvent = new ReplaySubject<string>(1);

  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  params: Params;
  hasAccountPlan: boolean;
  isReadOnlyMode: boolean;
  isAdmin: boolean;
  gridToolbarConfig: GridToolbarConfig;
  gridConfig: GridConfig;
  opportunities: Array<PhasingResponseItem> = [];
  isExpanded: boolean;
  selectedTarget: TextValuePair;

  faCircle = faCircle as IconProp;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private filtersService: FiltersService,
    private phasingGridService: PhasingGridService,
    private oppPhasingModalService: OpportunityPhasingModalService,
    private securityService: SecurityService,
    private appMessagesService: AppMessagesService,
    private appToastsService: AppToastsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private boardService: BoardService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.activatedRoute.queryParams,
      ]).subscribe(([w, x, y]: [Filters, SelectedFilters, Params]) => {
        this.filters = w;
        const timeframeChanged: boolean =
          (this.selectedFilters &&
            this.selectedFilters.timeframe.getDescription() !==
              x.timeframe.getDescription()) ||
          false;
        this.selectedFilters = x;
        const paramsChanged: boolean =
          (this.params && difference([this.params], [y])?.length > 0) || false;
        this.params = y;

        Promise.all([
          this.securityService.hasAccountPlanRole(
            this.selectedFilters.customer.MasterCustomerNumber
          ),
          this.securityService.isReadOnly(),
          this.securityService.isAdmin(),
        ]).then((z) => {
          const accountPlanChanged: boolean = this.hasAccountPlan !== z[0];
          this.hasAccountPlan = z[0];
          this.isReadOnlyMode = z[1];
          this.isAdmin = z[2];

          const boardChanged: boolean =
            this.boardService.selectedBoard?.tab?.Name ===
            TabControlNames.phasing;

          if (
            !this.gridToolbarConfig ||
            paramsChanged ||
            accountPlanChanged ||
            boardChanged
          ) {
            this.initializeToolbar();
            this.filtersService.updateTarget(this.selectedTarget);
            this.initializeGridConfig();
          }

          this.validateFilterSelection(timeframeChanged);

          this.getData();
        });
      })
    );

    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          if (!isUndefined(this.customerFilters)) {
            StorageUtils.remove(StorageType.SessionStorage, 'search-filter');
            this.gridToolbarConfig.searchText = null;
          }

          this.customerFilters = x;
        }
      )
    );

    this.subscription.add(
      this.router.events.subscribe((x: any) => {
        if (x instanceof ResolveEnd) {
          if (
            !x.urlAfterRedirects.includes('phasing') &&
            !x.urlAfterRedirects.includes(
              AppModulePaths.ieopportunity.subtabs.opportunities.fullpath
            )
          ) {
            StorageUtils.remove(StorageType.SessionStorage, 'search-filter');
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeToolbar(): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.selectedFilters
    );

    let selectedMetric: TextValuePair = GridFilters.Metrics[0];
    let selectedPeriod: TextValuePair = GridFilters.Periods[0];
    let selectedType: TextValuePair = GridFilters.Types[0];
    this.selectedTarget = this.selectedFilters.target ?? targets[0];

    let isNoActivity: boolean;
    let isMsaIndicator: boolean;

    if (
      this.boardService.selectedBoard?.tab?.Name === TabControlNames.phasing
    ) {
      const metricsDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.metrics
        );
      const periodDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.periods
        );
      const targetDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.targets
        );
      const selectedTypeDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.types
        );
      const expandSwitch = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.expand
      );
      isNoActivity = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.noActivity
      )?.value;
      isMsaIndicator = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.msaIndicator
      )?.value;

      selectedMetric = GridFilters.Metrics.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === metricsDropdown.value.toLowerCase()
      );

      selectedPeriod = GridFilters.Periods.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === periodDropdown.value.toLowerCase()
      );

      this.selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === targetDropdown.value.toLowerCase()
      );

      selectedType = GridFilters.Types.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === selectedTypeDropdown.value.toLowerCase()
      );

      this.isExpanded = expandSwitch?.value;
      this.expandChangedEvent.emit(this.isExpanded);

      this.boardService.selectedBoard = null;
    } else {
      selectedMetric = this.params?.metric
        ? GridFilters.Metrics.find((x: TextValuePair) => {
            const isPercentage: boolean = this.params.isPercentage === 'true';
            switch (Number(this.params.metric)) {
              case MetricType.Sales:
                return x.value === MetricsOptions.Sales;
              case MetricType.Revenue:
                return x.value === MetricsOptions.Revenue;
              case MetricType.Cci:
                return (
                  (x.value === MetricsOptions.Cci && !isPercentage) ||
                  (x.value === MetricsOptions.CciPercentage && isPercentage)
                );
              default:
                return x.value === MetricsOptions.Summary;
            }
          })
        : GridFilters.Metrics[0];

      selectedPeriod = this.params?.period
        ? GridFilters.Periods.find(
            (x: TextValuePair) => x.value === this.params.period
          )
        : GridFilters.Periods[1];

      selectedType = this.params?.stage
        ? GridFilters.Types.find(
            (x: TextValuePair) => x.value === this.params.stage
          )
        : GridFilters.Types[0];

      this.selectedTarget = this.params?.target
        ? targets.find((x: TextValuePair) => x.value === this.params.target)
        : this.selectedTarget;
    }

    if (selectedMetric.value !== GridFilters.Metrics[0].value) {
      this.metricChangedEvent.next(selectedMetric);
    }

    this.gridToolbarConfig = new GridToolbarConfig({
      componentName: ComponentNames.opportunities,
      controls: [
        new GridToolbarDropdown({
          controlName: ComponentNames.metrics,
          items: GridFilters.Metrics,
          onChange: this.onMetricChanged.bind(this),
          selected: selectedMetric,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.periods,
          items: GridFilters.Periods,
          onChange: this.onPeriodChanged.bind(this),
          selected: selectedPeriod,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.targets,
          items: targets,
          onChange: this.onTargetChanged.bind(this),
          selected: this.selectedTarget,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.types,
          items: GridFilters.Types,
          onChange: this.onOppTypeChanged.bind(this),
          selected: selectedType,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.export,
          icon: faDownload as IconProp,
          onClick: this.onFileExport.bind(this),
          text: 'EXPORT',
          isDisabled: (x: GridToolbarConfig) => {
            const targetsDropdown: GridToolbarDropdown =
              this.gridToolbarConfig.findControl(ComponentNames.targets);

            return (
              targetsDropdown.selected.value === ViewOptions.CompareProjection
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.expand,
          icon: faExpand as IconProp,
          onClick: () => {
            this.isExpanded = true;
            this.expandChangedEvent.emit(true);
          },
          text: 'EXPAND',
          isVisible: () => {
            return !this.isExpanded;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.compress,
          icon: faCompress as IconProp,
          onClick: () => {
            this.isExpanded = false;
            this.expandChangedEvent.emit(false);
          },
          text: 'EXIT',
          isVisible: () => {
            return this.isExpanded;
          },
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.noActivity,
          text: 'NO ACTIVITY',
          onSwitch: this.onNoActivitySwitchChanged.bind(this),
          switchValue: isNoActivity,
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.msaIndicator,
          text: 'MSA CVF',
          onSwitch: this.onMsaIndicatorSwitchChanged.bind(this),
          switchValue: isMsaIndicator,
        }),
      ],
      searchText: StorageUtils.get(StorageType.SessionStorage, 'search-filter'),
    });
  }

  initializeGridConfig(): void {
    this.gridConfig =
      this.gridConfig ??
      new GridConfig({
        columns: [],
        features: new GridConfigFeatures({
          hasTotalsRow: true,
        }),
        options: GridColDefs.getExtraGridOptions(this.filters),
        observables: new GridObservables({
          loadChanged: this.loadChangedEvent,
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
          filterChanged: this.filtersChangedEvent,
          expandChanged: this.expandChangedEvent,
        }),
      });

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.gridToolbarConfig,
        this.filters,
        this.selectedFilters,
        [
          new GridCellIcon({
            icon: faPencilAlt as IconProp,
            tooltip: 'Edit',
            onClick: this.onEditOpportunity.bind(this),
            showIconByRow: (x: PhasingResponseItem) => {
              return (
                x.IsCompleteFinancials &&
                !this.isReadOnlyMode &&
                this.hasAccountPlan
              );
            },
          }),
          new GridCellIcon({
            icon: faEye as IconProp,
            tooltip: 'View',
            onClick: this.onViewOpportunity.bind(this),
            showIconByRow: (x: PhasingResponseItem) => {
              return (
                x.IsCompleteFinancials &&
                (!this.hasAccountPlan || this.isReadOnlyMode)
              );
            },
          }),
        ]
      )
    );
  }

  getData(): void {
    this.loadChangedEvent.next(true);

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    const extraParams: Record<string, any> = {
      groupBy: 'Opportunity,Month',
      dataSet: targetsDropdown.selected.value,
      sapCode: '-1',
    };

    this.phasingGridService
      .getOpportunities(
        RequestPayload.createRequest(this.selectedFilters, extraParams)
      )
      .then((x: Array<PhasingResponseItem>) => {
        this.opportunities = x;
        this.showNotifications();
        this.updateRowDataEvent.next(this.mapToGridData(x));
        this.loadChangedEvent.next(false);
      });
  }

  mapToGridData(response: Array<PhasingResponseItem>): GridData {
    let opportunities: Array<PhasingResponseItem> = cloneDeep(response);

    const noActivitySwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.noActivity);

    const msaIndicatorSwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.msaIndicator);

    const typesDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.types);

    const metricsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.metrics);

    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    if (noActivitySwitch.switchValue) {
      opportunities = opportunities.filter(
        (x: PhasingResponseItem) =>
          !x.isInactive(
            metricsDropdown.selected.value,
            periodsDropdown.selected.value
          )
      );
    }

    if (msaIndicatorSwitch.switchValue) {
      opportunities = opportunities.filter(
        (x: PhasingResponseItem) => x.MsaIndicator
      );
    }

    switch (typesDropdown.selected.value) {
      case OpportunityTypes.Qualified:
        opportunities = opportunities.filter((x: PhasingResponseItem) =>
          x.isQualified()
        );
        break;
      case OpportunityTypes.Unqualified:
        opportunities = opportunities.filter((x: PhasingResponseItem) =>
          x.isUnqualfied()
        );
        break;
    }

    const isCustom: boolean =
      this.selectedFilters.timeframe.title === 'Custom' ? true : false;

    return new GridData({
      rows: PhasingGridUtils.mapData(
        opportunities,
        this.filters,
        this.gridToolbarConfig,
        isCustom
      ),
      pinnedTop: [],
    });
  }

  showNotifications(): void {
    const appDate: ActiveDate = this.filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    if (
      this.opportunities.some((x: PhasingResponseItem) => {
        return (
          GridColDefs.Shared.getOppDateId(
            this.filters,
            x.statusSinceDate?.toString()
          ) < appDate.StartTimeId ||
          GridColDefs.Shared.getOppDateId(
            this.filters,
            x.StartDate?.toString()
          ) < appDate.StartTimeId
        );
      })
    ) {
      MessageTemplates.OpportunitiesElapsedDates.id = `${AppModulePaths.phasing.id}-elapsed-${this.selectedFilters.customer.MasterCustomerNumber}`;
      this.appMessagesService.show(MessageTemplates.OpportunitiesElapsedDates, {
        centered: true,
      });
    }
  }

  validateFilterSelection(timeframeChanged?: boolean): void {
    let colDefsUpdated = false;

    const periodsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.periods);

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    GridValidationsUtils.validatePeriodSelection(
      periodsDropdown,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    GridValidationsUtils.validateSubMetricSelection(
      targetsDropdown,
      this.filters,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    if (timeframeChanged && !colDefsUpdated) {
      this.updateColDefs();
    }
  }

  onMetricChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
    this.metricChangedEvent.next(selected);
  }

  onPeriodChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onOppTypeChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onTargetChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.filtersService.updateTarget(selected);
    this.updateColDefs();
    this.getData();
  }

  onFileExport(): void {
    new Promise(
      (
        resolve: (x: Record<string, boolean>) => void,
        reject: () => void
      ): void => {
        const sgMessage: AppMessage = MessageTemplates.ExportSgBreakdown;
        const wmuMessage: AppMessage = MessageTemplates.ExportWmuBreakdown;
        let isSgBreakdown = false;
        let isWmuBreakdown = false;
        sgMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isSgBreakdown = x.text === 'Yes';
            this.appMessagesService.close(sgMessage.id);
            this.appMessagesService.show(wmuMessage, { centered: true });
          };
        });
        wmuMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isWmuBreakdown = x.text === 'Yes';
            this.appMessagesService.close(wmuMessage.id);
            resolve({
              isSgBreakdown,
              isWmuBreakdown,
            });
          };
        });
        this.appMessagesService.show(sgMessage, { centered: true });
      }
    ).then((x: Record<string, boolean>) => {
      this.appMessagesService.show(MessageTemplates.FileExporterInit, {
        centered: true,
      });
      this.phasingGridService
        .export(this.selectedFilters, this.gridToolbarConfig, x)
        .then((x: boolean) => {
          if (!x) {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        });
    });
  }

  onSearchTextChanged(searchText: string): void {
    StorageUtils.set(StorageType.SessionStorage, 'search-filter', searchText);
    this.filtersChangedEvent.next(searchText);
  }

  onNoActivitySwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onMsaIndicatorSwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onEditOpportunity(opportunity: OpportunityResponseItem): void {
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    this.oppPhasingModalService.openOppPhasingModalEmitted.next(
      new OpportunityPhasingModalConfig({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        customerFilters: this.customerFilters,
        dataSet: targetsDropdown.selected.value,
        isReadOnly: false,
        isAdmin: this.isAdmin,
        opportunityId: opportunity.Id,
      })
    );
  }

  onViewOpportunity(opportunity: OpportunityResponseItem): void {
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    this.oppPhasingModalService.openOppPhasingModalEmitted.next(
      new OpportunityPhasingModalConfig({
        filters: this.filters,
        selectedFilters: this.selectedFilters,
        customerFilters: this.customerFilters,
        dataSet: targetsDropdown.selected.value,
        isReadOnly: true,
        isAdmin: this.isAdmin,
        opportunityId: opportunity.Id,
      })
    );
  }
}
