export const environment = {
  production: true,
  providers: 'app',
  clientId: '7760.cbp.dev.web',
  contentType: 'application/json',
  baseServiceUrl:
    'https://8ghmlzpc5c.execute-api.us-east-1.amazonaws.com/devtest/',
  fileExporterBaseServiceUrl:
    'https://8ghmlzpc5c.execute-api.us-east-1.amazonaws.com/devtest/',
  tabsAvailability: {
    overview: true,
    backlog: true,
    analytics: true,
    ieopportunity: true,
    phasing: true,
    speculative: true,
  },
  logOutPath:
    'https://login.microsoftonline.com/common/oauth2/logout?client_id=00000003-0000-0ff1-ce00-000000000000&response_mode=form_post',
  configPath: 'config/config.devtest.json',
};
